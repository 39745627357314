import {
  CONST_USERNAME_MAX_SYMBOLS,
  CONST_USERNAME_MIN_SYMBOLS,
  GENDER_FEMALE,
  GENDER_MALE,
  MAX_USER_AGE,
  MIN_USER_AGE,
} from "shared/constants";
import {
  CONST_EMAIL_MAX_SYMBOLS,
  EMAIL_REGEXP,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "shared/constants/auth";

/**
 * Validate username
 * @param {string} name User name
 * @return {{error: {text:null, type: null}, status: boolean}}
 */
export const validateName = (name) => {
  const USERNAME_REGEX = /^[a-zA-Z\s’'-]+$/;
  const result = {
    status: false,
    error: {
      text: null,
      type: null,
    },
  };

  if (!name || name.trim().length === 0) {
    result.error = {
      text: "Field cannot be empty",
      type: "empty",
    };
    return result;
  }

  name = name.trim();

  if (name.length < CONST_USERNAME_MIN_SYMBOLS) {
    result.error = {
      text: `The username must contain more than ${CONST_USERNAME_MIN_SYMBOLS} characters.`,
      type: "too_short",
    };
    return result;
  }

  if (name.length > CONST_USERNAME_MAX_SYMBOLS) {
    result.error = {
      text: `The username must contain less than ${CONST_USERNAME_MAX_SYMBOLS} characters.`,
      type: "too_long",
    };
    return result;
  }

  if (!USERNAME_REGEX.test(name)) {
    result.error = {
      text: "Only letters (A - Z, a - z)",
      type: "invalid_chars",
    };
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Validate user gender
 * @param {number} gender User gender
 */
export const isValidGender = (gender) => {
  return !(
    typeof gender === "undefined" ||
    (gender !== GENDER_FEMALE && gender !== GENDER_MALE)
  );
};

/**
 * Validate user month
 * @param {number} month User month
 */
export const isValidMonth = (month) => {
  if (typeof month === "undefined" || month !== parseInt(month)) return false;

  return !(month.length <= 0 || month < 1 || month > 12);
};

/**
 * Validate user day
 * @param {number} day User day
 */
export const isValidDay = (day) => {
  if (typeof day === "undefined" || day !== parseInt(day)) return false;

  return !(day.length <= 0 || day < 1 || day > 31);
};

/**
 * Validate user year
 * @param {number} year User year
 */
export const isValidYear = (year) => {
  if (typeof year === "undefined" || year !== parseInt(year)) return false;
  else {
    const today = new Date();
    const age = today.getFullYear() - parseInt(year);

    if (age < MIN_USER_AGE || age > MAX_USER_AGE) {
      return false;
    }
  }

  return true;
};

/**
 * Validate user age
 * @param {number} month Month number
 * @param {number} day Day number
 * @param {number} year Year number
 * @return {{error: null|string, status: boolean}}
 */
export const validateAge = (month, day, year) => {
  const result = {
    status: false,
    error: null,
  };

  if (!month || !day || !year) {
    result.error = "Field cannot be empty";
    return result;
  }

  if (!isValidYear(year) || !isValidDay(day) || !isValidMonth(month)) {
    result.error = "Field cannot be empty";
    return result;
  }

  const age = Math.floor(
    (new Date() -
      new Date(
        year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (day < 10 ? "0" + day : day)
      ).getTime()) /
      3.15576e10
  );

  if (age < MIN_USER_AGE) {
    result.error = `Only users over ${MIN_USER_AGE} years old have access to the platform.`;
    return result;
  }

  result.status = true;

  return result;
};

/**
 * Validate user email
 * @param {string} email User email
 */
export const validateEmail = (email) => {
  const result = {
    status: false,
    error: null,
  };

  if (!email || email.length <= 0) {
    result.error = "Field cannot be empty.";

    return result;
  }
  if (typeof email.trim !== "undefined") {
    email = email.trim();
  }

  if (email.length >= CONST_EMAIL_MAX_SYMBOLS) {
    result.error = `Email must contain less than ${CONST_EMAIL_MAX_SYMBOLS} characters.`;

    return result;
  } else if (!EMAIL_REGEXP.test(email)) {
    result.error = `Your email address is not valid. Please, check it.`;
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Validate password
 * @param {string} password Password text
 * @param {null|string} repeatPass Password text
 * @param {boolean} isLogin Password boolean
 * @return {{errorPassword: {text: string, type: string}, errorRepeatPass: {text: string, type: string}, status: boolean}}
 */
export const validatePassword = (
  password,
  repeatPass = null,
  isLogin = false
) => {
  const result = {
    status: false,
    errorPassword: {
      text: "",
      type: "",
    },
    errorRepeatPass: {
      text: "",
      type: "",
    },
  };

  if (
    !password ||
    password.length <= 0 ||
    (typeof password.trim !== "undefined" && password.trim().length === 0)
  ) {
    result.errorPassword = {
      text: "Field cannot be empty.",
      type: "empty",
    };
    return result;
  }

  if (
    !isLogin &&
    (!repeatPass ||
      repeatPass.length <= 0 ||
      (typeof repeatPass.trim == "undefined" && repeatPass.trim().length === 0))
  ) {
    result.errorRepeatPass = {
      text: "Field cannot be empty.",
      type: "empty_repeat",
    };
    return result;
  }

  if (password.length > MAX_PASSWORD_LENGTH) {
    result.errorPassword = {
      text: `The password cannot be more than ${MAX_PASSWORD_LENGTH} characters.`,
      type: "too_long",
    };
    return result;
  }

  if (password.length < MIN_PASSWORD_LENGTH) {
    result.errorPassword = {
      text: `The password cannot be less than ${MIN_PASSWORD_LENGTH} symbols.`,
      type: "too_short",
    };
    return result;
  }

  if (!isLogin && password !== repeatPass) {
    result.errorRepeatPass = {
      text: "Passwords do not match",
      type: "not_matches",
    };
    return result;
  }

  result.status = true;
  return result;
};

/**
 * Check nullable string
 * @param {null|string|underfined} value checking string
 * @return {boolean}
 */
export const isStringEmpty = (value) => {
  return (
    value == null || (typeof value === "string" && value.trim().length === 0)
  );
};

/**
 * Validate user data object
 * @param {object} data user object
 */
export const isFullDataUserValidate = (data) => {
  const result = { valid: true, errors: {} };

  const { error, status } = validateName(data.first_name);

  if (!status) {
    result.valid = false;
    result.errors["first_name"] = error;
  }
  const resultGender = isValidGender(data.gender);
  if (!resultGender) {
    result.valid = false;
    result.errors["gender"] = "Field cannot be empty";
  }
  const resultAge = validateAge(data.month, data.day, data.year);
  if (!resultAge.status) {
    result.valid = false;
    result.errors["month"] = resultAge.error;
  }

  if (!data.month) {
    result.valid = false;
    result.errors["month"] = resultAge.error;
  }

  if (!data.day) {
    result.valid = false;
    result.errors["day"] = resultAge.error;
  }

  if (!data.year) {
    result.valid = false;
    result.errors["year"] = resultAge.error;
  }

  return result;
};
